<template>
  <b-container class="mt-5 mb-5">
    <b-button
      variant="outline-info"
      :to="{name: 'events'}"
    >
      <font-awesome-icon :icon="['fas', 'arrow-left']" /> Späť na prehľad sústredení
    </b-button>

    <LoadingSpinner
      :is-loaded="eventLoaded"
      class="mt-3"
    >
      <template v-if="isAccepted">
        <EventAttendanceDetail
          class="mt-3"
          :attendance="eventData.attendance"
          :event-id="eventData.id"
        />
        <hr>
      </template>
      <EventInformation :event="eventData" />

      <template v-if="!isAccepted">
        <div
          v-if="registrationClosed"
          class="mt-3"
        >
          <b-alert
            variant="warning"
            class="text-center"
            show
          >
            Prihlasovanie na toto sústredenie je uzavreté.
          </b-alert>
        </div>
        <div v-else-if="isInvited">
          <EventDecline
            :event="eventData"
            @updated="loadEvent"
          />

          <hr class="mt-4 mb-4">

          <EventForm
            class="mt-3"
            :event="eventData"
            @attendanceCreated="loadEvent"
          />
        </div>
        <EventUndecline
          v-else-if="isDeclined"
          :event="eventData"
          @updated="loadEvent"
        />
        <b-alert
          v-else-if="isCancelled"
          variant="danger"
          class="text-center mt-3"
          show
        >
          Tvoja prihláška bola zrušená. Ak si myslíš, že ide o chybu, kontaktuj organizátorov.
        </b-alert>

        <div
          v-else
          class="mt-3"
        >
          <b-alert
            v-if="$root.stateLoaded && $root.state.user"
            variant="warning"
            class="text-center"
            show
          >
            Prihlasovanie na toto sústredenie je k dispozícii len pre pozvaných.
          </b-alert>

          <b-alert
            v-else
            variant="warning"
            class="text-center"
            show
          >
            Pre prihlasovanie sa musíš najprv
            <b-button
              v-b-modal.login-modal
              variant="warning"
              size="sm"
            >
              prihlásiť
            </b-button>.
          </b-alert>
        </div>
      </template>
    </LoadingSpinner>
  </b-container>
</template>

<script>
import { apiEvents, EventForm, LoadingSpinner } from 'frontend-common'
import constants from '@/constants'
import EventAttendanceDetail from '@/components/EventAttendanceDetail.vue'
import EventDecline from '@/components/events/EventDecline'
import EventInformation from '@/components/EventInformation.vue'
import EventUndecline from '@/components/events/EventUndecline'

export default {
  name: 'EventDetail',
  components: {
    EventUndecline,
    EventDecline,
    EventInformation,
    EventForm,
    EventAttendanceDetail,
    LoadingSpinner,
  },
  mixins: [
    apiEvents,
  ],
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      eventLoaded: false,
      ongoingAttendanceChange: false,
      eventData: null,
    }
  },
  computed: {
    // The values here might need some adjustments for events which are not invite-only (private)
    isInvited() {
      return this.eventLoaded && this.eventData.attendance &&
        this.eventData.attendance.status === constants.attendance.status.INVITED
    },
    registrationClosed() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      // String conversion happens in order to ignore timezones
      return this.eventLoaded && (
        new Date(new Date(this.eventData.registration_start_date).toDateString()) > today ||
        new Date(new Date(this.eventData.registration_end_date).toDateString()) < today
      )
    },
    isAccepted() {
      return this.eventLoaded && this.eventData.attendance &&
        this.eventData.attendance.status === constants.attendance.status.ACCEPTED
    },
    isDeclined() {
      return this.eventLoaded && this.eventData.attendance &&
        this.eventData.attendance.status === constants.attendance.status.DECLINED
    },
    isCancelled() {
      return this.eventLoaded && this.eventData.attendance &&
        this.eventData.attendance.status === constants.attendance.status.CANCELLED
    },
  },
  watch: {
    $route() {
      this.loadEvent()
    },
    '$root.isAuthenticated': 'loadEvent',
  },
  mounted() {
    this.loadEvent()
  },
  methods: {
    loadEvent() {
      this.eventLoaded = false
      this.apiEvent(this.eventId).
        then(response => this.eventData = response).
        finally(() => this.eventLoaded = true)
    },
  },
}
</script>
