import { render, staticRenderFns } from "./EventDecline.vue?vue&type=template&id=17e3d529&scoped=true&"
import script from "./EventDecline.vue?vue&type=script&lang=js&"
export * from "./EventDecline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e3d529",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BAlert} from 'bootstrap-vue'
    installComponents(component, {BButton, BAlert})
    

export default component.exports