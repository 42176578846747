<template>
  <b-alert
    variant="danger"
    class="mt-5"
    show
  >
    <p>
      Ak už teraz vieš, že sa tohto sústredenia nebudeš môcť zúčastniť, môžeš prihlášku odmietnuť.
    </p>
    <p>
      Túto akciu môžeš kedykoľvek počas priebehu prihlasovania zrušiť.
    </p>

    <b-button
      variant="danger"
      :disabled="working"
      @click="submit"
    >
      <LoadingSpinner
        :is-loaded="!working"
        small
      >
        Odmietnúť prihlášku
      </LoadingSpinner>
    </b-button>
  </b-alert>
</template>

<script>
import {apiEvents} from 'frontend-common'

export default {
  name: 'EventDecline',
  mixins: [
    apiEvents,
  ],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      working: false,
    }
  },
  methods: {
    async submit() {
      this.working = true

      this.apiDeclineEvent(this.event.id).
        then(async () => {
          this.$root.successToast('Prihláška bola úspešne odmietnutá.')
          await this.$root.loadStatus()
          this.$emit('updated')
        }).
        catch(() => {
          this.$root.dangerToast(
            'Nastala chyba. Skús to znovu neskôr.',
          )
        }).
        finally(() => this.working = false)
    },
  },
}
</script>

<style scoped>

</style>
